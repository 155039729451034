import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface SeoProps {
  title: string;
  description: string;
  image: string;
  article: boolean;
  pathname: string;
}

const Seo = ({ title, description, image, article, pathname }: SeoProps) => {
  const { site } = useStaticQuery(query);
  const { siteMetadata } = site;
  const { siteUrl, twitterUsername } = siteMetadata.seo;

  const seo = {
    title: title, // || defaultTitle,
    description: description, // || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};
export default Seo;
// SEO.propTypes = {
//   title: PropTypes.string,
//   description: PropTypes.string,
//   image: PropTypes.string,
//   article: PropTypes.bool,
// }
// SEO.defaultProps = {
//   title: null,
//   description: null,
//   image: null,
//   article: false,
// }
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        seo {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          twitterUsername
        }
        socialAccounts {
          platform
          priority
          showInNav
          userName
          webUri
        }
      }
    }
  }
`;
