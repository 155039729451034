import React from "react";
import Layout from "cloud-elephants-core/src/components/Layout";
import Seo from "cloud-elephants-core/src/components/Seo";
import ContentBlock from "cloud-elephants-core/src/components/ContentBlock";

const template = {
  type: "",
  description: "",
  layoutOverrides: {},
  seo: {},
};

const ContentPageTemplate = ({ pageContext }) => {
  const { blocks, pagePath, pageData } = pageContext;
  console.log("pageContext", pageContext);
  return (
    <Layout>
      <Seo
        title={pageData.title}
        description={pageData.description}
        image={pageData.pageImg}
        article={pageData.isArticle}
        pathname={pagePath}
      />
      {blocks.map((b, i) => {
        return (
          <ContentBlock
            key={`${i}_${b.type}`}
            blockType={b.type}
            componentProps={b.props}
          />
        );
      })}
    </Layout>
  );
};

export default ContentPageTemplate;
